<template lang="pug">
    header.header(v-if="$store.getters['mobile-layout/showHeader']" :class="{'admin': $route.meta.layout == 'admin'}")
        router-link.header--logo(
            tag="img",
            :src="$route.meta.layout == 'admin' ? '/img/logo-header.png' : '/img/logo-header.png'",
            to="/home",
            @click.native.prevent="$store.commit('search/resetWrapper')"
        )
        img.header--notification-logo(src="@/assets/icon/Bell.svg" @click="$store.commit('mobile-layout/showNotificationMenu', true)")
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.extend({});
</script>

<style lang="scss" scoped>
.header {
    height: 56px;
    background-color: white;
    display: flex;
    position: relative;
    box-shadow: 0px 3px 9px var(--shadow-color);
    z-index: 1;

    &--logo {
        max-height: 30px;
        margin-top: 12px;
        margin-left: auto;
        margin-right: auto;

        &.admin {
            filter: var(--svg-filter-white-color)
        }
    }

    &--notification-logo {
        position: absolute;
        height: 24px;
        right: 16px;
        top: 16px;
    }

    &.admin {
        background: var(--text-color);

    }
}
</style>